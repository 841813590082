body {
    background-color: #282c34;
    scroll-behavior: smooth;
    transition: background-color 1s step-end;
    overflow: hidden;
}

body .invertedBg {
    background-color: #d2cdc5;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
}

h2 {
    font-family: 'Callheart';
    font-size: calc(12px + 8vmin);
    text-align: center;
    color: #d2cdc5;
}

h2.invertedBg {
    color: #000;
}

#main {
    flex: 1;
}

.galleryContainer {
    padding: 20px;
    /* z-index: 4; */
}
