.loadScreenContainer {
    flex: 1;
    display: flex;
    /* background: red; */
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    /* flex: 0; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* font-weight: bold; */
    position: relative;
    color: #fff;
    transition: color 1s ease-in-out;
    transition: background-color 1s step-end;
    background-color: #282c34;
    position: fixed;
    top: 0;
}

.loadScreenContainer.viewGallery {
    opacity: 0;
}

.loadScreenContainer.invertBg {
    background-color: #d2cdc5;
    color: #000;
}
.menu {
    position: absolute;
    bottom: 5%;
    z-index: 2;
}

.menu-item {
    /* font-family: 'Callheart'; */
    font-size: calc(10px + 3vmin);
    background: none;
    color: inherit;
    border: none;
    /* padding: 0; */
    cursor: pointer;
    outline: inherit;
}

.menu:hover {
    transform: scale(1.1);
}
