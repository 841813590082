.coolTextContainer {
    position: absolute;
}

.coolTextContainer h2 {
    /* color: white; */
    /* font-size: 8em; */
    font-size: calc(12px + 8vmin);
    position: absolute;
    transform: translate(-50%, -100%);
    width: max-content;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    user-select: none;
    /* background-clip: text; */
    transition: all 1s step-end;
}

.coolTextContainer h2:nth-child(1) {
    color: transparent;
    /* -webkit-text-stroke: 4px #03a9f4; */
    -webkit-text-stroke: calc(1px + 1vmin / 4) #03a9f4;
    /* z-index: 1; */
}

.coolTextContainer.invertBg h2:nth-child(1) {
    -webkit-text-stroke: calc(1px + 1vmin / 4) #ff4a24;
}

.coolTextContainer h2:nth-child(2) {
    color: #03a9f4;
    /* background: -webkit-linear-gradient(#02ffcc, #001eff); */
    /* background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    animation: animate 4s ease-in-out infinite;
}

.coolTextContainer.invertBg h2:nth-child(2) {
    color: #ff4a24;
}

@keyframes animate {
    0%,
    100% {
        clip-path: polygon(
            0% 45%,
            16% 44%,
            33% 50%,
            54% 60%,
            70% 61%,
            84% 59%,
            100% 52%,
            100% 100%,
            0% 100%
        );
    }

    50% {
        clip-path: polygon(
            0% 60%,
            15% 65%,
            34% 66%,
            51% 62%,
            67% 50%,
            84% 45%,
            100% 46%,
            100% 100%,
            0% 100%
        );
    }
}
