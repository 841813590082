#expandingCircleContainer {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: none; */
}

.circle {
    z-index: 1;
    position: fixed;
    width: 150px; /* Initial width of the circle */
    height: 150px; /* Initial height of the circle */
    background-color: #fff;
    border-radius: 50%;
    mix-blend-mode: exclusion;
    transform: translate(-50%, -50%);
    /* Smooth transition for scaling */
    /* transition: transform 1s ease-in-out; */
    /* transition: opacity 1s ease-in; */
    /* transition: 'opacity 0.15s ease-in-out, height 0.2s ease-in-out, width 0.2s ease-in-out'; */
    /* transition: height 1s linear, width 1s linear, border-radius 1s linear; */
}

.invert {
    transition: transform 1s ease-in-out, border-radius 1s ease-in-out,
        opacity 1s step-end;
    border-radius: 0;
    transform: scale(32);
    opacity: 0;
    pointer-events: none;
}

.normal {
    transition: opacity 1s ease-out;
}
